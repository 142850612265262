import { Button, Form, Input, Row, Col, Checkbox, Divider } from "antd";
import styles from "./LoginForm.module.css";
import { cls, stdLogin } from "../../utils/frontend/utils";
import { validateEmailRegex } from "../../utils/common/utils";
import { useRouter } from "next/router";
import { login } from "../../utils/frontend/fetchFromApi";
import { useEffect, useState, Fragment, useRef } from "react";
import { ExclamationCircleOutlined, MailFilled } from "@ant-design/icons";
import Reaptcha from "reaptcha";
import PasswordVisibilityIcon from "../PasswordVisibilityIcon";
import { REMEMBERED_EMAIL, COLORS, LOGIN_ERROR_ENUM } from "../../const";
import { useStore } from "../../hooks/useStore";
import { useUserAction } from "../../hooks/useWorker";
import { useTranslation } from "react-i18next";
import { Text } from "../SharedComponents";

import Link from "next/link";

const IconStyle = {
  color: COLORS["gray-7"],
  fontSize: "16px",
};

function EmailIcon() {
  return <MailFilled style={IconStyle} />;
}

export default function LoginForm({ setUserInfo, onNext, cookies, isSlackApp }) {
  const { t } = useTranslation("", { keyPrefix: "loginForm" });
  const [, collect] = useUserAction();
  const [store, setStore] = useStore();
  const [form] = Form.useForm();
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberedEmail, setRememberedEmail] = useState("");

  const router = useRouter();

  const onFinish = async (values, recaptchaResponse) => {
    setIsLoading(true);

    // Hack for cypress test
    const forceTfa = router.query.forceTfa;
    const loginResp = await login(values, recaptchaResponse);

    collect("login", `login response`, {
      email: values?.email,
      loginResp,
      userId: values?.email, // since the store is not initialized yet, collect() doesn't have access to the userId to store in the DB. we are passing it manually to collect() here.
    });

    if (
      !loginResp ||
      !loginResp.access_token ||
      !loginResp.id_token ||
      loginResp.isTfaNeeded == null ||
      loginResp.deactivated == null
    ) {
      recaptchaRef.current?.reset();
      loginResp.error === LOGIN_ERROR_ENUM.ACCOUNT_NOT_EXIST ||
      loginResp.error === LOGIN_ERROR_ENUM.INVALID_EMAIL_ADDRESS
        ? setEmailErrorMsg(loginResp.errMsg)
        : setPasswordErrorMsg(loginResp.errMsg);
      setIsLoading(false);
      return;
    }
    if (loginResp.deactivated === true) {
      setEmailErrorMsg("This account is deactivated.");
      setIsLoading(false);
      return;
    }

    if (values.remember === true) {
      localStorage.setItem(REMEMBERED_EMAIL, values.email);
    } else {
      localStorage.removeItem(REMEMBERED_EMAIL);
    }

    if (loginResp.isTfaNeeded === true || forceTfa === "true") {
      // need tfa
      setUserInfo({
        email: values.email,
        password: values.password,
        accessToken: loginResp.access_token,
        idToken: loginResp.id_token,
      });
      onNext();
    } else {
      // skip tfa and login
      // const onSuccess = isSlackApp ? () => {} : () => router.push(`${queryString}`);
      stdLogin(
        {
          userId: values.email,
          password: values.password,
          accessToken: loginResp.access_token,
          idToken: loginResp.id_token,
        },
        () => {}
      );
      setStore((prev) => ({
        ...prev,
        loggedIn: true,
      }));
    }
  };

  useEffect(() => {
    setRememberedEmail(localStorage.getItem(REMEMBERED_EMAIL));
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberedEmail]);

  useEffect(() => {
    if (store?.userInfo) {
      console.debug(store.userInfo);
    }
  }, [store?.userInfo]);

  const isFromDatadog = cookies["isFromDatadog"] === "true";

  return (
    <Fragment>
      <Form
        name="login"
        form={form}
        initialValues={{
          email: rememberedEmail,
          remember: true,
        }}
        onFinish={
          isFromDatadog
            ? () => onFinish(form.getFieldsValue(), "")
            : () => recaptchaRef?.current?.execute?.()
        }
      >
        <Row justify="space-between">
          <Col>
            <div className={cls(styles, ["field"])}>{t("email")}</div>
          </Col>
          <Col>
            <div className={cls(styles, ["error-msg"])}>{emailErrorMsg ? emailErrorMsg : " "}</div>
          </Col>
        </Row>
        <Form.Item
          name="email"
          normalize={(value) => (value || "").toLowerCase()}
          validateTrigger={"onBlur"}
          rules={[
            {
              validateTrigger: ["onBlur"],
              validator(rule, value) {
                setEmailErrorMsg("");
                if (value == null || value === "") {
                  setEmailErrorMsg(t("emailRequired"));
                  return Promise.reject();
                }
                if (!validateEmailRegex(value)) {
                  setEmailErrorMsg(t("invalidEmail"));
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            data-cy="login_email"
            className={cls(styles, ["input"])}
            suffix={
              emailErrorMsg ? (
                <ExclamationCircleOutlined className={cls(styles, ["error-icon"])} />
              ) : (
                <EmailIcon />
              )
            }
            onChange={() => setEmailErrorMsg("")}
          />
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <div className={cls(styles, ["field"])}>{t("password")}</div>
          </Col>
          <Col>
            <div className={cls(styles, ["error-msg"])}>
              {passwordErrorMsg ? passwordErrorMsg : " "}
            </div>
          </Col>
        </Row>
        <Form.Item
          name="password"
          validateTrigger={"onBlur"}
          rules={[
            {
              validateTrigger: ["onBlur"],
              validator(rule, value) {
                setPasswordErrorMsg("");
                if (value == null || value === "") {
                  setPasswordErrorMsg(t("passwordRequired"));
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            data-cy="login_password"
            type={isPasswordVisible ? "text" : "password"}
            className={cls(styles, ["input"])}
            suffix={
              passwordErrorMsg ? (
                <ExclamationCircleOutlined className={cls(styles, ["error-icon"])} />
              ) : (
                <PasswordVisibilityIcon
                  isPasswordVisible={isPasswordVisible}
                  setIsPasswordVisible={setIsPasswordVisible}
                />
              )
            }
            onChange={() => setPasswordErrorMsg("")}
          />
        </Form.Item>
        <Row justify="space-between" align="middle">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox className={cls(styles, ["checkbox"])} style={{ fontSize: 13 }}>
              {t("rememberMe")}
            </Checkbox>
          </Form.Item>
          <Link passHref href="/reset-password">
            {t("forgotPassword")}
          </Link>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            loading={isLoading}
            disabled={isLoading}
            className={cls(styles, ["login-btn"])}
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Row justify="space-between">
        <Text level={2} color="gray-800">
          {t("doNotHaveAccount")}
        </Text>
        <Link passHref href={isSlackApp ? `/signup?slackApp` : `/signup`}>
          {t("signUp")}
        </Link>
      </Row>
      {!isFromDatadog && (
        <Reaptcha
          ref={recaptchaRef}
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
          onVerify={(resp) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onFinish(form.getFieldsValue(), resp);
          }}
          size="invisible"
        />
      )}
    </Fragment>
  );
}
