import AppEntryLayout from "../../components/AppEntryLayout";
import StepWizard from "../../components/Login/StepWizard";
import SlackAuthLogin from "../../components/Login/SlackAuthLogin";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  AUTH_DATA,
  DEFAULT_FEATURE_PAGE_FALLBACK_ORDER,
  FEATURE_MAP,
  FEATURE_ROUTE_MAP,
  USER_ID,
} from "../../const";
import { defaultStore, useStore } from "../../hooks/useStore";
import Redirecting from "../../components/Redirecting";
import { useTranslation } from "react-i18next";
import { Box } from "../../components/SharedComponents";
import { stdLogout } from "../../utils/frontend/utils";
import { localStore } from "../../utils/frontend/localStore";
import { AppType } from "../../types/common/common";
import { globalWarningNotification } from "../../components/SharedComponents/notification";

const APP = process.env.NEXT_PUBLIC_APP;

export default function Login({ cookies, consumedInvitation, slackApp, force }) {
  const [store, setStore] = useStore();
  const router = useRouter();
  const { t } = useTranslation();
  const [destinationPageName, setDestinationPageName] = useState("");

  useEffect(() => {
    consumedInvitation && globalWarningNotification(`Invitation link has already been used`);
    if (force === "true") {
      void router.push("/login");
      void stdLogout().then(() => {
        setStore(defaultStore);
      });
    }

    // Only run once on page load. DO NOT add deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.query.trigger === "logout") {
      void stdLogout().then(() => {
        setStore(defaultStore);
        void router.replace("/login", undefined, { shallow: true });
      });
      return;
    }

    if (store?.userInfo?.authData) {
      const userID = localStore.get(USER_ID);
      const authData = localStore.get(AUTH_DATA);
      if (!userID || !authData) {
        void stdLogout().then(() => {
          setStore(defaultStore);
        });
        return;
      }
      const path = router.asPath.slice(router.asPath.indexOf("#") + 1);
      const enabledFeatures = store.userInfo?.userConfig?.featureList ?? [];
      // if go to default page by app
      const pageName = getDefaultPageName(enabledFeatures, store?.userInfo);
      if (APP === AppType.SKYINSIGHTS && pageName) {
        const { name, route } = FEATURE_ROUTE_MAP[pageName];
        setDestinationPageName(name);
        !slackApp && router.push(path.includes("/login") ? route : path);
      }
      // if user has audit access and also want to go to audit page
      else if (
        APP === AppType.SKYHARBOR &&
        enabledFeatures.includes(FEATURE_MAP?.audit?.id)
        // path.startsWith("/audits")
      ) {
        setDestinationPageName("Audits page");
        !slackApp &&
          router.push(
            path.includes("/login") || path.includes("/risk-manager")
              ? "/risk-manager/risk-inspector"
              : path
          );
        console.log("path: ", path);
      }
      // otherwise
      else {
        console.log("RedirectCard-->app", APP);
        setDestinationPageName("Account page");
        !slackApp && router.push("/account");
      }
      setStore((prev) => ({
        ...prev,
        loggedIn: true,
      }));
    }
  }, [router, slackApp, store?.userInfo]);

  const stdLogin = (userID, accessToken, idToken, tokenType, func = () => {}) => {
    localStore.set(USER_ID, userID);
    localStore.set(
      AUTH_DATA,
      JSON.stringify({
        email: userID,
        accessToken,
        idToken,
        tokenType,
      })
    );
    func();
  };
  const getDefaultPageName = (feature, userInfo = null) => {
    if (userInfo && userInfo?.source && userInfo?.source !== "invitationCode") {
      FEATURE_ROUTE_MAP["tfa"] = {
        id: "tfa",
        name: "Transaction Flow Analyzer",
        type: "page",
        route: "/tfa",
      };
      FEATURE_ROUTE_MAP["fund_tracking"] = {
        id: "fund_tracking",
        name: "Fund Tracking",
        type: "page",
        route: "/fund-tracking",
      };
    }
    const enableUrl = Object.entries(feature)
      .filter(([key, value]) => ["all", "write"].includes(value) && !!FEATURE_ROUTE_MAP[key]?.route)
      .map(([url]) => url)
      .sort((a, b) => {
        const aOrder = DEFAULT_FEATURE_PAGE_FALLBACK_ORDER.includes(a)
            ? DEFAULT_FEATURE_PAGE_FALLBACK_ORDER.indexOf(a)
            : 999,
          bOrder = DEFAULT_FEATURE_PAGE_FALLBACK_ORDER.includes(b)
            ? DEFAULT_FEATURE_PAGE_FALLBACK_ORDER.indexOf(b)
            : 999;
        return aOrder - bOrder;
      });
    return enableUrl[0];
  };

  return !store.loggedIn ? (
    <AppEntryLayout title={t("loginForm.login")}>
      <Box width={450} ml="auto" mr="auto" mt="48px">
        {<StepWizard cookies={cookies} isSlackApp={slackApp} />}
      </Box>
    </AppEntryLayout>
  ) : slackApp ? (
    <AppEntryLayout title={t("loginForm.login")}>
      <Box width={450} ml="auto" mr="auto" mt="48px">
        <SlackAuthLogin />
      </Box>
    </AppEntryLayout>
  ) : (
    <Redirecting toPage={destinationPageName} />
  );
}

export function getServerSideProps(context) {
  const { consumedInvitation, slackApp, force } = context.query;
  return {
    props: {
      cookies: context.req.cookies,
      consumedInvitation: consumedInvitation != undefined,
      slackApp: slackApp != undefined,
      force: force || null,
    }, // will be passed to the page component as props
  };
}
