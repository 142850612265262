import React, { useEffect, useState } from "react";
import { Form, Button, Switch, Input, Row, Spin } from "antd";
import { InfoCircleFilled, LoadingOutlined } from "@ant-design/icons";
import VerificationToken from "../../components/VerificationTokenInput/VerificationToken";
import { validateLoginTotp } from "../../utils/frontend/fetchFromApi";
import styles from "./TfaForm.module.css";
import { cls, stdLogin } from "../../utils/frontend/utils";
import { useStore } from "../../hooks/useStore";
import { useUserAction } from "../../hooks/useWorker";
import { COLORS } from "../../const";
import { Title } from "../SharedComponents";

export default function TfaForm({ userInfo, isSlackApp }) {
  const [store, setStore] = useStore();
  const [, collect] = useUserAction();
  const [form] = Form.useForm();
  const [useToken, setUseToken] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [state, setState] = useState({
    token: null,
    filled: false,
    dataURL: null,
    tokenResult: null,
    lockLoading: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    const authData = {
      email: userInfo.email,
      accessToken: userInfo.accessToken,
      idToken: userInfo.idToken,
    };
    const response = await validateLoginTotp(
      useToken ? state.token : null,
      useToken ? null : state.code,
      userInfo.email,
      authData
    );
    if (response.result !== true) {
      setErrorMessage(`Invalid ${useToken ? "token" : "backup code"}`);
      setIsLoading(false);
      return Promise.reject();
    }

    collect("login", `tfa response`, {
      email: userInfo.email,
      response,
      userId: userInfo?.email, // since the store is not initialized yet, collect() doesn't have access to the userId to store in the DB. we are passing it manually to collect() here.
    });

    // const onSuccess = isSlackApp ? () => {} : () => router.push(`${queryString}`);
    stdLogin(
      {
        userId: userInfo.email,
        password: userInfo.password,
        accessToken: userInfo.accessToken,
        idToken: userInfo.idToken,
      },
      () => {}
    );
    setStore((prev) => ({
      ...prev,
      loggedIn: true,
    }));
    return Promise.resolve();
  };

  const onTokenChanged = (token) => {
    setState((prev) => ({
      ...prev,
      token,
    }));
  };

  const onCodeChanged = (e) => {
    setState((prev) => ({
      ...prev,
      code: e.target.value,
    }));
  };

  const tokenItem = (
    <div>
      <Form.Item
        name="token"
        validateTrigger={["onChange", "onBlur", "onTokenChanged"]}
        valuePropName="currentValue"
        trigger="onTokenChanged"
        rules={[
          {
            validator: () => {
              const { token } = state;
              console.log(token);
              if (token == null || token.length !== 6) {
                return Promise.reject();
              }
              return onFinish();
            },
          },
        ]}
      >
        <VerificationToken
          tokenLength={6}
          onTokenChanged={onTokenChanged}
          currentValue={state.token}
        />
      </Form.Item>
    </div>
  );

  const codeItem = (
    <div>
      <Form.Item
        name="code"
        validateTrigger={"onChange"}
        rules={[
          {
            validateTrigger: ["onChange", "onBlur"],
            validator: () => {
              const { code } = state;
              if (code == null || code.length < 1) {
                return Promise.reject();
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input onChange={onCodeChanged} TextArea={{ value: state.code }} />
      </Form.Item>
    </div>
  );

  const tfaEnabledForm = (
    <div className={cls(styles, ["tfa-form-body"])}>
      <div className={cls(styles, ["subtitle"])}>
        Protecting your account is our top priority. Enter 6 digit token below from the 2FA provider
        that you selected during sign up.{" "}
        <a
          href="https://support.google.com/accounts/answer/185839"
          target="_blank"
          style={{ verticalAlign: "text-bottom" }}
          rel="noreferrer"
        >
          <InfoCircleFilled style={{ color: COLORS["gray-10"] }} />
        </a>
      </div>
      <Row justify="space-between" style={{ marginBottom: "24px" }}>
        <div>Use Two-Factor Authentication</div>
        <div>
          <Switch
            style={{
              backgroundColor: useToken ? COLORS.certikRed : undefined,
            }}
            id="switchButtonLogin"
            checked={useToken}
            onClick={() => setUseToken(!useToken)}
          />
        </div>
      </Row>

      <Row style={{ marginBottom: "8px" }}>
        <div>{useToken ? "Enter 6 digit token" : "Enter 2FA backup code"}</div>
      </Row>

      {useToken === true ? tokenItem : codeItem}
    </div>
  );

  useEffect(() => {
    if (store?.userInfo) {
      console.debug(store.userInfo);
    }
  }, [store?.userInfo]);

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
      <Title level={2} color="gray-800" size={20} weight={500}>
        Two Factor Authentication (2FA)
      </Title>
      <Form
        onFinish={onFinish}
        form={form}
        name="verification-form"
        layout="vertical"
        hideRequiredMark
      >
        {tfaEnabledForm}
        <div>
          {!useToken && (
            <Button
              data-testid="tfa-login"
              htmlType="submit"
              block
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading}
              className={cls(styles, ["btn"])}
            >
              Login
            </Button>
          )}
        </div>
      </Form>
      <div className={cls(styles, ["error-msg"])}>{errorMessage}</div>
    </Spin>
  );
}
